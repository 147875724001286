import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { mainBreakPoints } from 'shared/constants/index';
import { Collapse, ConfirmButton, IconButton, Layout, Tooltip, Island, Button } from 'shared/components';
import resolveUrl from 'shared/utils/resolveUrl';
import MDIcon from 'shared/components/MDIcon';

const breakpointMatches = matchMedia(`(min-width: ${mainBreakPoints.MEDIUM}px)`).matches;

const PrimaryDimensionIcon = () => (
    <MDIcon
        icon="looks_one"
        modifierClass="u-color-positive u-margin-right-tiny"
        isFilled
        title="Pääsuodatindimensio"
    />
);

/**
 * Dimensiontyypinsolu. Renderöi näytön koosta riippuen koko dimensiontyypin tiedot tai vaan tyypin nimen.
 */
export default class DimensionTypeCell extends Component {

    static propTypes = {
        original: PropTypes.object,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        isDeleting: PropTypes.bool,
        route: PropTypes.string.isRequired,
    };

    static defaultProps = {
        original: {},
        onEdit() {},
        onDelete() {},
        isDeleting: false,
    };

    state = {
        isCollapseOpen: false,
    };

    renderCollapse() {
        const { original } = this.props;
        return (
            <Collapse isOpen={this.state.isCollapseOpen}>
                <Island isInset>
                    <div className="o-stack o-stack--justify">
                        <div>
                            <div className="u-muted">{_trans('company_manager.dimensions.list.cells.is_contract')}</div>
                            <div className="u-text-capitalize">{_.get(original, 'isContract', false) ? _trans('yes') : _trans('no')}</div>
                        </div>
                        <div>
                            <div className="u-muted">{_trans('company_manager.dimensions.list.cells.is_time_registration')}</div>
                            <div className="u-text-capitalize">{_.get(original, 'isTimeRegistration', false) ? _trans('yes') : _trans('no')}</div>
                        </div>
                    </div>
                    <div className="o-stack o-stack--justify">
                        <div>
                            <div className="u-muted">{_trans('company_manager.dimensions.list.cells.is_restricted')}</div>
                            <div className="u-text-capitalize">{_.get(original, 'isRestricted', false) ? _trans('yes') : _trans('no')}</div>
                        </div>
                    </div>
                    <div className="o-stack o-stack--justify">
                        <div>
                            <div className="u-muted">{_trans('company_manager.dimensions.list.cells.is_salary_type')}</div>
                            <div className="u-text-capitalize">{_.get(original, 'isRestricted', false) ? _trans('yes') : _trans('no')}</div>
                        </div>
                    </div>
                </Island>
            </Collapse>
        );
    }

    render() {
        const { original, onEdit, onDelete, isDeleting, route } = this.props;
        const name = _.get(original, 'name');
        const id = _.get(original, 'id');
        const dimensionsCount = _.get(original, 'dimensions', []).length;
        if (breakpointMatches) {
            return (
                <div>
                    {original?.isPrimarySearchDimension && <PrimaryDimensionIcon />}
                    <Link to={resolveUrl(route, { typeId: id })}>
                        {name}
                    </Link>
                </div>
            );
        }
        return (
            <div>
                <Layout.Container>
                    <Layout.Item medium="1/2" small="1/2" modifierClass="u-margin-top-small">
                        <div>
                            <div className="u-text-capitalize">
                                {original?.isPrimarySearchDimension && <PrimaryDimensionIcon />}
                                <Link to={resolveUrl(route, { typeId: id })}>
                                    {name}
                                </Link>
                            </div>
                        </div>
                        <div className="u-text-left u-margin-top-small">
                            <Button
                                flat
                                onClick={() => this.setState({ isCollapseOpen: ! this.state.isCollapseOpen })}
                                mdIcon={this.state.isCollapseOpen ? 'expand_less' : 'expand_more'}
                                modifierClass="u-margin-top-tiny"
                            >
                                {_trans('text.extra_info')}
                            </Button>
                        </div>
                    </Layout.Item>
                    <Layout.Item medium="1/2" small="1/2" modifierClass="u-margin-top-small">
                        <div>
                            <IconButton
                                mdIcon="edit"
                                flat
                                onClick={() => onEdit(id)}
                            >
                                {_trans('button.edit')}
                            </IconButton>
                        </div>
                        <div>
                            <ConfirmButton
                                mdIcon="clear"
                                flat
                                modifierClass="u-margin-top-tiny"
                                disabled={dimensionsCount > 0}
                                text={_trans('button.remove')}
                                confirmText={_trans('button.are_you_sure')}
                                onClick={() => onDelete(id)}
                                isPending={isDeleting}
                            />
                            {dimensionsCount > 0 && (
                                <Tooltip title={_trans('company_manager.dimensions.notifications.error.has_values')}/>
                            )}
                        </div>
                    </Layout.Item>
                </Layout.Container>
                {this.renderCollapse()}
            </div>
        );
    }
}
