import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { highlight, languages } from 'prismjs/components/prism-core';
import { TabContainer } from 'shared/components';
import { Dialog } from 'shared/components/Dialog';
import 'prismjs/components/prism-json';

const JsonHighlight = ({ json }) => (
    <pre
        className="language-json"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(highlight(JSON.stringify(json, null, 4), languages['json'], 'json')) }}
    >
    </pre>
);

JsonHighlight.propTypes = {
    json: PropTypes.array.isRequired,
};

export const FormikDebugDialog = ({ initialValues, values, errors, isOpen, onClose }) => {
    const hasErrors = Object.keys(errors ?? {}).length > 0;
    return (
        <Dialog
            title="Formik debug"
            isVerticallyCentered={false}
            isOpen={isOpen}
            onClose={onClose}
            maxWidth={800}
        >
            <TabContainer
                tabs={{
                    formik: {
                        label: 'Formik state',
                        content: <JsonHighlight json={Array.isArray(values) ? values : [values]} />
                    },
                    errors: {
                        label: 'Virheet',
                        badge: hasErrors ? Object.keys(errors ?? {}).length : null,
                        badgeType: hasErrors ? 'negative' : null,
                        content: <JsonHighlight json={Array.isArray(errors) ? errors : [errors]} />
                    },
                    initialValues: {
                        label: 'Initial values',
                        content: <JsonHighlight json={Array.isArray(initialValues) ? initialValues : [initialValues]} />
                    },
                }}
            />
        </Dialog>
    );
};

FormikDebugDialog.propTypes = {
    initialValues: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

