import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { select } from '@rematch/select';
import _ from 'lodash';
import DimensionCell from '../components/DimensionCell';
import {
    ActionBar,
    MDSpinner,
    Page,
    LinkIcon,
    Button,
    Panel,
} from 'shared/components';
import DataTable from 'shared/containers/DataTable';
import { mainBreakPoints } from 'shared/constants/index';
import resolveUrl from 'shared/utils/resolveUrl';
import { dimension, dimensionType } from 'shared/shapes/dimensions';
import confirm from 'shared/utils/confirm';

const breakpointMatches = matchMedia(`(min-width: ${mainBreakPoints.MEDIUM}px)`).matches;

const mobileFilter = (filter, row) => {
    const value = _.get(row, '_original.value', '');
    const description = _.get(row, '_original.description', '');
    return value.toLowerCase().includes(filter.value.toLowerCase()) || description.toLowerCase().includes(filter.value.toLowerCase());
};

@withRouter
@connect((state, props) => ({
    dimensionTypes: select.dimensions.getDimensionTypes(state),
    dimensions: select.dimensions.getDimensionType(state),
    isLoadingDimensions: _.get(state, 'loading.effects.dimensions.fetchDimensionType', false),
    typeId: _.get(props, 'match.params.typeId'),
    isDeleting: _.get(props, 'loading.effects.dimensions.deleteDimension', false),
}))
export default class DimensionsList extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        dimensions: PropTypes.arrayOf(dimension),
        isLoadingDimensions: PropTypes.bool,
        typeId: PropTypes.string,
        isDeleting: PropTypes.bool,
        routes: PropTypes.object.isRequired,
        dimensionTypes: PropTypes.arrayOf(dimensionType),
    };

    static defaultProps = {
        dispatch() {},
        dimensions: {},
        isLoadingDimensions: false,
        typeId: '',
        isDeleting: false,
        dimensionTypes: [],
    };

    constructor(props) {
        super(props);
        props.dispatch.dimensions.fetchDimensionType(props.typeId);
    }

    onEdit = (id) => this.props.dispatch(
        push(resolveUrl(this.props.routes.DIMENSION_FORM, { typeId: this.props.typeId, dimensionId: id }))
    );

    onDelete = (id) => this.props.dispatch.dimensions.deleteDimension({ id, typeId: this.props.typeId });

    render() {
        const { dimensions, isLoadingDimensions, typeId, isDeleting, routes, dimensionTypes } = this.props;

        const dimensionType = dimensionTypes.find((dimType) => dimType.id === parseInt(typeId, 10));

        if (isLoadingDimensions) {
            return <MDSpinner wrapped/>;
        }
        return (
            <Page
                heading={dimensionType?.name ?? _trans('company_manager.dimensions.dimensions.heading')}
                isBodyClear
                goBack={{ to: routes.DIMENSION_TYPES, text: _trans('link.return') }}
            >
                <ActionBar hasMargin={breakpointMatches}>
                    <LinkIcon mdIcon="add" to={resolveUrl(routes.DIMENSION_FORM, { typeId, dimensionId: 'new', })}>
                        {_trans('company_manager.dimensions.dimensions.heading_create')}
                    </LinkIcon>
                </ActionBar>
                <DataTable
                    data={_.get(dimensions, 'dimensions', []).reverse()}
                    emptyDataMessage={_trans('company_manager.dimensions.list.no_dimensions')}
                    actionsColumnWidth={340}
                    actionsColumn={breakpointMatches ? <ActionColumn isDeleting={isDeleting} onDelete={this.onDelete} onEdit={this.onEdit} /> : null}
                    columns={[
                        {
                            Header: breakpointMatches ? _trans('company_manager.dimensions.list.cells.value') : '',
                            id: 'dimensionCell',
                            accessor: 'value',
                            Cell: (props) =>
                                <DimensionCell
                                    isDeleting={isDeleting}
                                    onDelete={this.onDelete}
                                    onEdit={this.onEdit}
                                    {...props}
                                />,
                            filterMethod: breakpointMatches ? null : mobileFilter,
                        },
                        {
                            Header: _trans('company_manager.dimensions.list.cells.description'),
                            accessor: 'description',
                            show: breakpointMatches,
                        },
                        {
                            Header: _trans('company_manager.dimensions.list.cells.additional_info'),
                            accessor: 'additionalInfo',
                            show: breakpointMatches,
                        },
                        {
                            Header: _trans('company_manager.dimensions.list.cells.allowed_hours'),
                            accessor: 'allowedHours',
                            show: breakpointMatches,
                            width: 100,
                        },
                    ]}
                    SubComponent={
                        (props) => (
                            <Panel heading={_trans('company_manager.dimensions.list.cells.free_text')} modifierClass="u-margin-top-tiny u-text-pre-line">
                                {_.get(props, 'original.freeText')}
                            </Panel>
                        )
                    }
                />
            </Page>
        );
    }
}

const ActionColumn = ({ onEdit, onDelete, isDeleting, original: { id } }) => (
    <ActionBar alignItems="left">
        <Button
            ghost
            mdIcon="edit"
            onClick={() => onEdit(id)}
        >
            {_trans('button.edit')}
        </Button>
        <Button
            ghost
            mdIcon="delete"
            inProgress={isDeleting}
            onClick={() => {
                confirm(_trans('button.are_you_sure'), {
                    proceedLabel: _trans('Poista', {}, 'common'),
                    alert: true,
                }).then(() => onDelete(id));
            }}
        >
            {_trans('button.remove')}
        </Button>
    </ActionBar>
);

ActionColumn.propTypes = {
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    original: PropTypes.object,
};

ActionColumn.defaultProps = {
    original: {},
};

