import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { SummaryBlocks } from 'ContractV3/ContractSummary/containers/SummaryBlocks';
import Button from 'shared/components/Button';
import {
    getReportingFormBadgeTypeByState,
    reportingFormStates, translateReportingFormState
} from 'shared/ReportingForms/constants/reportingFormStates';
import Badge from 'shared/components/Badge';
import { componentRootPaths } from 'shared/ReForm/constants/componentRootPaths';
import ActionBar from 'shared/components/ActionBar';
import confirm from 'shared/utils/confirm';
import { useDocumentTitle } from 'shared/hooks/useDocumentTitle';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { Value } from 'shared/components/Value';
import SummaryList from 'shared/components/SummaryList/index';

export const ReportingFormSummary = ({ reportingForm, isConfirming, onEdit, onConfirm, onUnconfirm, onRemove }) => {
    const template = reportingForm?.template;
    const templateName = _transObj(template?.name ?? {});
    const reportingFormState = reportingForm?.state;
    const isReportingFormOpen = reportingFormState === reportingFormStates.OPEN;
    const isReportingFormDraft = reportingFormState === reportingFormStates.DRAFT;
    const isReportingFormConfirmed = reportingFormState === reportingFormStates.CONFIRMED;
    const isCompany = useSelector(select.userMetadata.isCompany);
    const isCommune = useSelector(select.userMetadata.isCommune);
    const isOperator = isCompany || isCommune;

    useDocumentTitle(templateName, {
        restoreOnUnmount: true,
    });

    return (
        <Fragment>
            <h1 className="c-heading-page u-margin-bottom-tiny u-margin-right-small">
                {templateName}
            </h1>
            <div className="u-margin-bottom">
                <Badge
                    type={getReportingFormBadgeTypeByState(reportingFormState)}
                    value={translateReportingFormState(reportingFormState)}
                />
            </div>
            <ActionBar isSticky alignItems="justify">
                {(isReportingFormOpen || isReportingFormDraft) && (
                    <Button
                        mdIcon="edit"
                        onClick={onEdit}
                        disabled={isConfirming}
                    >
                        {_trans('Muokkaa', {}, 'common')}
                    </Button>
                )}
                {isReportingFormConfirmed && (
                    <Fragment>
                        <Button
                            outline
                            mdIcon="print"
                            onClick={() => window.print()}
                        >
                            {_trans('Tulosta', {}, 'common')}
                        </Button>
                        {(reportingForm?.canUnconfirm && isOperator) && (
                            <Button
                                mdIcon="undo"
                                onClick={() => {
                                    confirm(_trans('Haluatko varmasti peruuttaa vahvistuksen?', {}, 'reporting_forms'), {
                                        alert: true,
                                        cancelLabel: _trans('Peruuta', {}, 'common'),
                                        proceedLabel: _trans('Peruuta vahvistus', {}, 'common'),
                                    }).then(() => {
                                        onUnconfirm();
                                    });
                                }}
                            >
                                {_trans('Peruuta vahvistus', {}, 'common')}
                            </Button>
                        )}
                    </Fragment>
                )}
                {(isReportingFormOpen || isReportingFormDraft) && (
                    <div>
                        <Button
                            negative
                            mdIcon="delete"
                            onClick={() => {
                                confirm(_trans('Haluatko varmasti poistaa tämän lomakkeen?', {}, 'reporting_forms'), {
                                    alert: true,
                                    cancelLabel: _trans('Peruuta', {}, 'common'),
                                    proceedLabel: _trans('Poista lomake', {}, 'common'),
                                }).then(() => {
                                    onRemove();
                                });
                            }}
                        >
                            {_trans('Poista lomake', {}, 'reporting_forms')}
                        </Button>
                        {isOperator && (
                            <Button
                                positive
                                mdIcon="check"
                                onClick={onConfirm}
                                inProgress={isConfirming}
                            >
                                {_trans('Vahvista', {}, 'common')}
                            </Button>
                        )}
                    </div>
                )}
            </ActionBar>
            <div className="u-margin-vertical">
                <div className="u-margin-bottom">
                    <SummaryList>
                        <SummaryListItem term={_trans('Kirjauksen selite/nimi', {}, 'reporting_forms')}>
                            <Value>
                                {reportingForm.description ?? '-'}
                            </Value>
                        </SummaryListItem>
                    </SummaryList>
                </div>
                <SummaryBlocks
                    json={template.data}
                    values={reportingForm.data}
                    componentRootPath={componentRootPaths.REPORTING_FORM}
                    templateSubType={template?.subType}
                />
            </div>
        </Fragment>
    );
};

ReportingFormSummary.propTypes = {
    reportingForm: PropTypes.object.isRequired,
    isConfirming: PropTypes.bool,
    onEdit: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onUnconfirm: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
};

ReportingFormSummary.defaultProps = {
    isConfirming: false,
};
