import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { RadioListField } from 'shared/components/Formik';
import { translateContractType } from 'shared/constants/contractTypes';
import { parseCustomTranslations } from 'shared/ReForm/utils/parseCustomTranslations';
import { Value } from 'shared/components/Value';

/**
 * Sopimustyypin valinta.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const ContractType = (props) => {
    const { name, isValueFixed, defaultValue, attributes: { translations } } = props;

    const isLoading = useSelector((state) => state.loading.effects.contract.fetchCollectiveAgreementRestrictions);

    const presetData = useSelector(select.contract.getPresetData);
    const restrictions = useSelector(select.contract.getCollectiveAgreementRestrictions);

    // Vedetään kustomkäännökset listasta objekteja => objektiksi jolloin arvo saadaan suoraan keyllä
    const options = useMemo(() => parseCustomTranslations(translations), [translations]);
    const hasCustomTranslator = Array.isArray(translations);

    // Sopimustyypit ja niiden käännökset
    const contractTypeOptions = useMemo(() => {
        const customTranslator = (type) => type in options ? options[type] : _trans('Tuntematon', {}, 'common');

        const salaryRestrictions = restrictions.allowedContractTypesAndSalaryTypes ?? [];

        return salaryRestrictions.map((restriction) => ({
            value: restriction.primaryType?.toString(),
            label: hasCustomTranslator
                ? customTranslator(restriction.primaryType)
                : translateContractType(restriction.primaryType),
        }));
    }, [hasCustomTranslator, options, restrictions.allowedContractTypesAndSalaryTypes]);

    const presetContractType = useMemo(() => {
        const presetValue = isValueFixed && defaultValue
            ? defaultValue?.toString()
            : presetData[name]?.toString();

        return contractTypeOptions.find((option) => option?.value === presetValue);
    }, [name, presetData, contractTypeOptions, defaultValue, isValueFixed]);

    // Rajoituksia vielä ladataan
    if (isLoading) return null;

    if (presetContractType) {
        return (
            <Value>
                {presetContractType?.label ?? '-'}
            </Value>
        );
    }

    return (
        <RadioListField name={name} options={contractTypeOptions} />
    );
};

ContractType.propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
    isValueFixed: PropTypes.bool,
    attributes: PropTypes.object,
};

ContractType.defaultProps = {
    attributes: {},
    defaultValue: false,
    isValueFixed: false,
};

export default ContractType;
