import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ActionBar, MDSpinner, Page, SubmitButton, Popover } from 'shared/components';
import { StField, StLabel } from 'shared/components/StForm';
import FormikErrors from 'shared/components/Formik/FormikErrors';
import resolveUrl from 'shared/utils/resolveUrl';
import FormikMaxTextArea from 'shared/components/Formik/FormikMaxTextArea';
import { dimension, dimensionType } from 'shared/shapes/dimensions';
import GoBackLink from 'shared/components/GoBackLink';

const initialValues = {
    value: '',
    description: '',
    additionalInfo: '',
    allowedHours: '',
    isHiddenOnList: false,
    salesInvoiceCustomerNumber: '',
    benefitDecisionMandateTemplateName: '',
};

@withRouter
@connect((state, props) => ({
    typeId: _.get(props, 'match.params.typeId'),
    dimensionType: select.dimensions.getDimensionType(state),
    dimension: select.dimensions.getDimensionByDimensionId(state, _.get(props, 'match.params.dimensionId')),
    dimensionId: _.get(props, 'match.params.dimensionId'),
    isNew: ! parseInt(_.get(props, 'match.params.dimensionId'), 10),
    isLoading: _.get(state, 'loading.effects.dimensions.fetchDimensionType', false),
    isSaving: _.get(state, 'loading.effects.dimensions.postDimension', false)
        || _.get(state, 'loading.effects.dimensions.putDimension', false),
    isAdmin: select.userMetadata.isAdmin(state),
    isCommuneAdmin: select.userMetadata.isCommuneAdmin(state),
}))
export default class DimensionForm extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        typeId: PropTypes.string,
        isNew: PropTypes.bool,
        dimensionType,
        isLoading: PropTypes.bool,
        dimension,
        isSaving: PropTypes.bool,
        routes: PropTypes.object.isRequired,
        isAdmin: PropTypes.bool,
        isCommuneAdmin: PropTypes.bool,
    };

    static defaultProps = {
        dispatch() {},
        typeId: '',
        isNew: false,
        dimensionType: {},
        isLoading: false,
        dimension: {},
        isSaving: false,
        isAdmin: false,
        isCommuneAdmin: false,
    };

    constructor(props) {
        super(props);
        props.dispatch.dimensions.fetchDimensionType(props.typeId);
    }

    onDimensionSubmit(model) {
        const { isNew, dispatch, typeId, routes } = this.props;
        if (isNew) {
            dispatch.dimensions.postDimension({ ...model, typeId }, resolveUrl(routes.DIMENSIONS, { typeId }));
        } else {
            dispatch.dimensions.putDimension({ ...model, typeId }, resolveUrl(routes.DIMENSIONS, { typeId }));
        }
    }

    validateDimensionValue(value) {
        const { dimensionType, isNew } = this.props;
        if (isNew) {
            // Validoidaan vain uudet
            return ! _.get(dimensionType, 'dimensions', [])
                .filter((dimension) => _.get(dimension, 'value') === value).length > 0;
        }
        return true;
    }

    render() {
        const { typeId, isNew, dimensionType, dimension, isLoading, isSaving, routes, isAdmin, isCommuneAdmin } = this.props;
        if (isLoading) {
            return <MDSpinner wrapped/>;
        }
        return (
            <Page
                heading={_trans(isNew ?
                    'company_manager.dimensions.dimensions.heading_create' :
                    'company_manager.dimensions.dimensions.heading_update')}
                isBodyClear
                maxWidth="medium-2"
                goBack={{ to: resolveUrl(routes.DIMENSIONS, { typeId }), text: _trans('link.return_without_saving') }}

            >
                <Formik
                    initialValues={isNew ? initialValues : dimension}
                    onSubmit={(model) => this.onDimensionSubmit(model)}
                    validationSchema={Yup.object().shape({
                        value: Yup.string()
                            .required(_trans('validation.required'))
                            .test('value', _trans('company_manager.dimensions.dimensions.form.dimension_value.non_unique'), (value) => this.validateDimensionValue(value))
                            .test('value', _trans('validation.required'), (value) => value?.trim().length !== 0),
                        allowedHours: Yup.number().typeError('Syötä numeerinen arvo'),
                        salesInvoiceCustomerNumber: Yup.number(),
                    })}
                >
                    {(props) => (
                        <form className="o-form o-form--responsive" onSubmit={props.handleSubmit}>
                            <StLabel id="dimension_type">
                                {_trans('company_manager.dimensions.dimensions.form.dimension_type')}
                            </StLabel>
                            <StField ariaLabelledby="dimension_type">
                                <span className="u-text-capitalize u-font-weight-bold">{_.get(dimensionType, 'name', '')}</span>
                            </StField>
                            <StLabel id="value">
                                {_trans('company_manager.dimensions.dimensions.form.dimension_value.label')}
                            </StLabel>
                            <StField ariaLabelledby="value">
                                <Field type="text" name="value" />
                                <FormikErrors name="value"/>
                            </StField>
                            <StLabel htmlFor="description">
                                {_trans('company_manager.dimensions.dimensions.form.dimension_description')}
                            </StLabel>
                            <StField>
                                <Field type="text" name="description" id="description" />
                                <FormikErrors name="description"/>
                            </StField>
                            <StField isLabeless>
                                <label>
                                    <Field type="checkbox" name="isHiddenOnList" id="isHiddenOnList" />
                                    {_trans('company_manager.dimensions.dimensions.form.hidden_on_list')}
                                </label>
                            </StField>
                            <StLabel
                                htmlFor="additionalInfo"
                                tooltip={_trans('company_manager.dimensions.dimensions.form.dimension_info_hide')}
                            >
                                {_trans('company_manager.dimensions.dimensions.form.dimension_additional_info')}
                            </StLabel>
                            <StField>
                                <Field type="text" name="additionalInfo" id="additionalInfo" />
                            </StField>
                            <StLabel
                                htmlFor="allowedHours"
                                tooltip={_trans('company_manager.dimensions.dimensions.form.dimension_hours_info')}
                            >
                                {_trans('company_manager.dimensions.dimensions.form.dimension_allowed_hours')}
                            </StLabel>
                            <StField>
                                <Field type="text" name="allowedHours" id="allowedHours" />
                                <FormikErrors name="allowedHours" />
                            </StField>
                            {dimensionType.isSalesInvoiceGrouping && (isAdmin || isCommuneAdmin) && (
                                <Fragment>
                                    <StLabel
                                        modifierClass="u-color-admin"
                                        htmlFor="salesInvoiceCustomerNumber"
                                    >
                                        {_trans('Dimension Procountor/Netvisor asiakasnumero')}
                                    </StLabel>
                                    <StField>
                                        <Field className="u-color-admin" type="text" name="salesInvoiceCustomerNumber" id="salesInvoiceCustomerNumber" />
                                        <FormikErrors name="salesInvoiceCustomerNumber" />
                                    </StField>
                                </Fragment>
                            )}
                            {dimensionType.isVisibilityDimension && dimensionType.isBenefitDecisionDimension && isCommuneAdmin && (
                                <Fragment>
                                    <StLabel
                                        modifierClass="u-color-admin"
                                        htmlFor="benefitDecisionMandateTemplateName"
                                    >
                                        Valtakirja-pohjan nimi (pyydä apua devaukselta)
                                    </StLabel>
                                    <StField>
                                        <Field className="u-color-admin" type="text" name="benefitDecisionMandateTemplateName" id="benefitDecisionMandateTemplateName" />
                                        <FormikErrors name="benefitDecisionMandateTemplateName" />
                                    </StField>
                                </Fragment>
                            )}
                            <div>
                                <div className="o-stack--inline u-margin-bottom-small">
                                    {_trans('company_manager.dimensions.dimensions.form.dimension_free_text')}
                                    <Popover>
                                        {_trans('company_manager.dimensions.dimensions.form.dimension_info_hide')}
                                    </Popover>
                                </div>
                                <div>
                                    <FormikMaxTextArea
                                        name="freeText"
                                        maxLength={5000}
                                        modifierClass="u-1/1"
                                        rows="6"
                                    />
                                </div>
                            </div>
                            <ActionBar>
                                <GoBackLink
                                    to={resolveUrl(routes.DIMENSIONS, { typeId })}
                                    text={_trans('link.return_without_saving')}
                                />
                                <SubmitButton isPending={isSaving} mdIcon="check">
                                    {_trans('button.save')}
                                </SubmitButton>
                            </ActionBar>
                        </form>
                    )}
                </Formik>
            </Page>
        );
    }
}
