import api from 'api';
import contractTemplateStates from 'shared/constants/contractTemplateStates';

export const getBasicTemplates = (templates) => templates
    .filter((template) => template.presetData?.isReSignatureNeeded === false);

export const getSignatureTemplates = (templates) => templates
    .filter((template) => ! template?.presetData || template.presetData?.hasOwnProperty('isReSignatureNeeded') === false);

/**
 * Sopimuspohjat.
 */
export const contractTemplates = {
    state: [],

    reducers: {
        setTemplates: (state, templates) => templates,
    },

    selectors: {
        /**
         * Kaikki sopparipohjat.
         */
        getTemplates: (state) => state,
        getTemplateName: (state) => _transObj(state.name ?? {}),
        getActiveTemplates: (state) => state.filter((template) => template.state === contractTemplateStates.STATE_ACTIVE),
        findTemplateById: (state, id) => state.find((template) => template.id === id)
    },

    effects: (dispatch) => ({
        /**
         * Hakee aktiiviset ja korvatut pohjat.
         * @returns {Promise<void>}
         */
        async fetchTemplates() {
            try {
                const response = await api.get(`/api/v3/templates?state[]=${contractTemplateStates.STATE_ACTIVE}&state[]=${contractTemplateStates.STATE_REPLACED}`);
                this.setTemplates(response.data ?? []);
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Sopimuspohjia ei voitu hakea.', {}, 'contractBuilder'));
            }
        },

        async fetchTemplate(templateId) {
            try {
                return await api.get(`/api/v3/templates/${templateId}`);
            } catch (e) {
                dispatch.notifications.addError('Sopimuspohjaa ei löydy.', {}, 'jobContract');
            }
        }
    })
};
