import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import userShapes from 'shared/shapes/userShapes';
import Avatar from 'shared/components/Avatar';
import utils from 'shared/utils/commonUtils';
import { sizes } from 'shared/constants';
import { resolveUserUrlByRelationType } from 'shared/utils/resolveUserUrlByRelationType';
import { Accordion, AccordionItem, UserAvatar } from 'shared/components/index';
import { UserSummary } from 'Commune/User/components/UserSummary';
import MDIcon from 'shared/components/MDIcon';

function ExperienceCell({ workExperience }) {
    const lastHistoryRow = workExperience.history[workExperience.history.length - 1];
    const years = lastHistoryRow.workExperienceYears;
    const totalMonths = lastHistoryRow.workExperienceMonths;
    // Bäkkäri palauttaa kertyneen kokemuksen vuosina tai kuukausina
    // Kerrotaan vuodet 12kk ja poistetaan saatu kk määrä kertyneistäkuukausista,
    // niin saadaan haluttu vuosi ja kk määrä. esim 1v 4kk
    const months = totalMonths - years * 12;

    return (
        <div>
            <div>
                {_trans('Työkokemus', {}, 'common')}
            </div>
            <div>
                <div>
                    <strong>{workExperience.name}</strong>
                </div>
                <div>
                    {_trans('users.section.work_experience.experience_year_month_total', { years, months, totalMonths })}
                </div>
            </div>
        </div>
    );
}

ExperienceCell.propTypes = {
    workExperience: PropTypes.shape(({
        name: PropTypes.string,
        history: PropTypes.arrayOf(PropTypes.shape({
            workExperienceYears: PropTypes.number,
            workExperienceMonths: PropTypes.number,
        }))
    })).isRequired,
};

function UserBlock(props) {
    const {
        user,
        email,
        isInfoVisible,
        hasMinimalInfo,
        isSsnVisible,
        isLoading,
        size,
        hasLastNameFirst,
        secondaryInfo,
        hasAccordion,
        isEmployee,
        hasLinkToDetails,
        relationType,
        workExperienceHistory,
    } = props;

    if (isLoading) {
        return (
            <div className="o-flag o-flag--tiny">
                <span className="o-flag__img u-font-size-0">
                    <Avatar hasPlaceholder size={size} />
                </span>
                <span className="o-flag__body">
                    <div className="u-placeholder u-placeholder--text">&nbsp;</div>
                    <div className="u-placeholder u-placeholder--text">&nbsp;</div>
                </span>
            </div>
        );
    } else {
        const fallBackInfo = user.socialSecurityNumber || user.phoneNumber || user.email;
        const additionalData = secondaryInfo ? _.get(user, secondaryInfo, '-') : fallBackInfo;

        const userEmail = _.get(user, 'email', '-');
        const itsYou = email === userEmail;
        const { isUserIdentified = false } = user;

        // Linkki käyttäjän tietoihin
        const linkToDetails = resolveUserUrlByRelationType(user.userId, relationType);
        const canShowLink = hasLinkToDetails && linkToDetails;

        const mediumInfo =
            <span className="o-flag__body">
                <strong className="c-heading-subheading u-display-block">
                    {React.createElement(canShowLink ? 'a' : 'span', {
                        href: canShowLink ? linkToDetails : null,
                        className: 'u-display-inline-block u-align-middle'
                    }, utils.getUserFullName(user))}
                    {(isUserIdentified && hasLinkToDetails) && (
                        <MDIcon
                            icon="verified_user"
                            size="small"
                            isFilled
                            title={_trans('Tunnistautunut.', {}, 'common')}
                            modifierClass="u-color-positive u-margin-left-tiny u-cursor-default"
                        />
                    )}
                </strong>
                {(itsYou && ! isSsnVisible) && (
                    <span> (sinä)</span>
                )}
                {isInfoVisible && (
                    <span title={_trans(isSsnVisible ? 'text.ssn' : 'text.email')}>
                        { isSsnVisible ? additionalData : userEmail }
                    </span>
                )}
            </span>;

        const minimalInfo = isInfoVisible && (
            <span className="o-flag__body">
                <strong className="c-heading-subheading">{user.email}</strong>
            </span>
        );

        const content = (
            <div className="o-flag o-flag--tiny">
                <span className="o-flag__img u-font-size-0">
                    <UserAvatar user={user} size={size} hasLastNameFirst={hasLastNameFirst} />
                </span>
                <div className="u-font-weight-normal">
                    {hasMinimalInfo ? minimalInfo : mediumInfo}
                </div>
            </div>
        );

        // Näytetään lisätietoja haitarissa
        if (hasAccordion) {
            return (
                <Accordion>
                    <AccordionItem customTitle={content}>
                        <UserSummary isShowingNameAndSsn={false} isEmployee={isEmployee} user={user} />
                        {workExperienceHistory.length === 1 && (
                            <ExperienceCell workExperience={workExperienceHistory[0]} />
                        )}
                    </AccordionItem>
                </Accordion>
            );
        }
        return content;
    }
}

UserBlock.propTypes = {
    isEmployee: PropTypes.bool,
    user: PropTypes.oneOfType([
        userShapes.MINIMAL,
        userShapes.MEDIUM
    ]).isRequired,
    email: PropTypes.string,
    isSsnVisible: PropTypes.bool,
    isInfoVisible: PropTypes.bool,
    hasMinimalInfo: PropTypes.bool,
    isLoading: PropTypes.bool,
    size: PropTypes.oneOf(_.map(sizes)),
    hasLastNameFirst: PropTypes.bool,
    secondaryInfo: PropTypes.string,
    hasAccordion: PropTypes.bool,
    /**
     * Direct link to user details.
     */
    hasLinkToDetails: PropTypes.bool,
    relationType: PropTypes.oneOf(['customer', 'employer', 'employee', null]),
    workExperienceHistory: PropTypes.array,
};

UserBlock.defaultProps = {
    isEmployee: false,
    email: '',
    isInfoVisible: true,
    hasMinimalInfo: false,
    isSsnVisible: true,
    isLoading: false,
    size: 'medium',
    hasLastNameFirst: true,
    hasAccordion: false,
    hasLinkToDetails: false,

    /**
     * Mikä lisätieto käyttäjästä haetaan? Hakee user-objektista lodashin _.get:llä.
     * Oletuksena jokin näistä: ssn, puhelinnumero tai sähköposti.
     */
    secondaryInfo: null,
    relationType: null,
    workExperienceHistory: [],
};

export default UserBlock;
