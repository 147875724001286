import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Creatable } from 'react-select';
import VirtualizedSelect from 'react-virtualized-select';
import createFilterOptions from 'react-select-fast-filter-options';
import classNames from 'classnames';
import {
    EmployeeOptionRenderer,
    LinkRenderer,
} from './components';
import api from 'api';
import { componentSizes } from 'shared/constants';

// Valinnanrenderöintityypit
const optionRendererTypes = {
    EMPLOYEE: 'employee',
    LINK: 'link',
};

const debouncedCallback = (callback, delay) => {
    let timerId;
    return (...args) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => callback(...args), delay);
    };
};

const AutoCompleteVirtualized = ({
    items,
    optionHeight,
    labelKey,
    valueKey,
    hasSingleValue,
    isAsync,
    asyncEndPoint,
    asyncQueryString,
    filterOptions,
    canCreateOptions,
    defaultValue,
    filterResults,
    size,
    maxCreatableLength,
    hasClearValueOnSelect,
    isMulti,
    isClearable,
    onSelect,
    onSelectAsync,
    onError,
    optionRenderer,
    isPostRequest,
    ...rest
}) => {
    const [hasHitMaxCreatableLength, setHasHitMaxCreatableLength] = useState(false);
    const [selectValue, setSelectValue] = useState('');
    const [options, setOptions] = useState([]);

    /* eslint-disable-next-line */
    const loadOptionsDebounced = useCallback(debouncedCallback((value, callback) => {
        const inputValue = value?.toString().trim();
        if (!inputValue || inputValue.trim().replace('  ') === '') {
            callback(null, { options: [] });
            setOptions([]);
            return;
        }

        const url = `${asyncEndPoint}${asyncQueryString}=${inputValue}`;
        // HUOM! Kyssärimerkkiä ei lisätä automaattisesti
        const request = isPostRequest ? api.post(url) : api.get(url);

        return request
            .then((response) => {
                const options = filterResults(response?.data ?? []);

                // Alles klar, päivitetään tulokset ja tapetaan spinneri
                if (response.status ==='ok') {
                    callback(null, { options });
                    setOptions(options);
                } else {
                    onError('Haku epäonnistui');
                    callback(null, { options: [] });
                    setOptions([]);
                }
            });
    }, 350), [asyncEndPoint, asyncQueryString, filterResults, onError]);

    useEffect(() => {
        setSelectValue(defaultValue);
    }, [defaultValue]);

    /**
     * Resolvoidaan käytettävä renderöijä. Toistaiseksi vain yksi.
     * @param optionRendererType
     * @returns function|null
     */
    const resolveOptionRenderer = (optionRendererType) => {
        switch (optionRendererType) {
            case optionRendererTypes.EMPLOYEE:
                return EmployeeOptionRenderer;
            case optionRendererTypes.LINK:
                return LinkRenderer;
        }
    };

    /**
     * Käytetään joko oletusrenderöijää tai kustomoitua.
     * @returns {Function}
     */
    const getOptionRenderer = () => {
        if (typeof optionRenderer === 'string') {
            return resolveOptionRenderer(optionRenderer);
        }

        return optionRenderer;
    };

    const selectFilterOptions = filterOptions ? filterOptions : createFilterOptions({ options: isAsync ? options : items, valueKey, labelKey });

    return (
        <VirtualizedSelect
            className={classNames({
                'u-animation-shake u-color-negative': hasHitMaxCreatableLength,
                'Select--large': size === componentSizes.LARGE,
            })}
            cache={false}
            multi={isMulti}
            async={isAsync}
            backspaceRemoves={! isAsync}
            autoload={false}
            loadOptions={loadOptionsDebounced}
            minimumInput={3}
            options={ isAsync ? options : items }
            optionHeight={optionHeight}
            value={selectValue ?? defaultValue}
            onChange={(selectValue) => {
                // Palautetaan objektista vain sen arvo
                const returnValue = hasSingleValue && selectValue && typeof selectValue === 'object'
                    ? selectValue[valueKey] ?? null
                    : selectValue;

                setSelectValue(hasClearValueOnSelect ? '' : selectValue);
                onSelect(returnValue);
                if (isAsync) {
                    onSelectAsync(selectValue);
                }
            }}
            labelKey={labelKey}
            valueKey={valueKey}
            searchable
            filterOptions={selectFilterOptions}
            selectComponent={ canCreateOptions ? Creatable : null }
            promptTextCreator={(label) => (<div>{_trans('text.create_option')} <b>{label}</b></div>)}
            clearValueText={_trans('multi_select.clear_value')}
            noResultsText={_trans('multi_select.no_result_text')}
            onInputChange={(value) => {
                // Jos voi luoda omia, niin voidaan antaa maksimimerkkipituus
                if (canCreateOptions && !! maxCreatableLength && value.length > maxCreatableLength) {
                    setHasHitMaxCreatableLength(true);
                    setTimeout(() => {
                        setHasHitMaxCreatableLength(false);
                    }, 500);
                    return value.substr(0, maxCreatableLength);
                }
                return value;
            }}
            clearable={isClearable}
            {...rest}
            optionRenderer={getOptionRenderer()}
        />
    );
};

AutoCompleteVirtualized.propTypes = {
    // Itemit, joista tehdään selecti
    items: PropTypes.array,

    // Yhden option-itemin korkeus. Oletuksena 36.
    optionHeight: PropTypes.number,

    // Mitä itemin objektin keytä käytetään labelina
    labelKey: PropTypes.string,

    // Filteröinti kohdistuu tähän defaulttina
    valueKey: PropTypes.string,

    // Annetaanko valitessa ulos vain yksi arvo jos valinta on objekti.
    hasSingleValue: PropTypes.bool,

    // Valittu arvo tulee ulos
    onSelect: PropTypes.func,

    // Async-valittu arvo
    onSelectAsync: PropTypes.func,

    // Käytettävä renderöijä. Joko funktio tai olemassa oleva renderöijä jonka tunniste annetaan stringinä.
    optionRenderer: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf(Object.values(optionRendererTypes))]),

    // async toggle
    isAsync: PropTypes.bool,

    // Jos async, niin mistä endpointista heataan dataa.
    asyncEndPoint: PropTypes.string,

    // Millä query stringillä haetaan dataa. Defaultti q
    asyncQueryString: PropTypes.string,

    // Jos tulee virhe, nin tällä passataan se.
    onError: PropTypes.func,

    // Jos halutaan käyttää omaa filtteröinti metodia.
    // TODO: Jostain käsittämättömästä syystä koko AutoComplete ei toimi jos tälle on annettu default-arvo, olkoonkin
    // vaikka undefined, null tai false.
    /* eslint-disable-next-line react/require-default-props */
    filterOptions: PropTypes.func,

    // Voidaanko uusia vaihtoehtoja luoda itse?
    canCreateOptions: PropTypes.bool,

    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Mahdollinen tulosten filtteröinti.
     */
    filterResults: PropTypes.func,

    // Komponentin koko
    size: PropTypes.oneOf(['', componentSizes.LARGE]),

    /**
     * Mahdollinen maksimi pituus luotavalle entrylle
     */
    maxCreatableLength: PropTypes.number,

    /**
     * Teksti kun async-haku käytössä.
     */
    searchPromptText: PropTypes.string,

    /**
     * Tyhjennetäänkö sisältö valittaessa.
     */
    hasClearValueOnSelect: PropTypes.bool,

    /**
     * Voidaanko valinta poistaa X:stä.
     */
    isClearable: PropTypes.bool,

    /**
     * Useamman valinta
     */
    isMulti: PropTypes.bool,

    /**
     * Tehdäänkö request GET:nä vai POST:na
     */
    isPostRequest: PropTypes.bool,
};

AutoCompleteVirtualized.defaultProps = {
    items: [],
    optionHeight: 36,
    labelKey: 'label',
    valueKey: 'value',
    hasSingleValue: false,
    onSelect() {},
    onSelectAsync() {},
    optionRenderer: null,
    isAsync: false,
    asyncEndPoint: '',
    asyncQueryString: 'q',
    onError() {},
    canCreateOptions: false,
    defaultValue: '',
    filterResults: (results) => results,
    size: '',
    maxCreatableLength: null,
    searchPromptText: _trans('autocomplete.search_prompt_text'),
    loadingPlaceholder: _trans('autocomplete.loading_placeholder'),
    hasClearValueOnSelect: false,
    isClearable: true,
    isMulti: false,
    isPostRequest: false,
};

export default AutoCompleteVirtualized;
