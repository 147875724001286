import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Feedback,
    ThumbUp,
    Button,
    Switch,
} from 'shared/components';

/**
 * Hakee ja näyttää pankkinapit joiden kautta tunnistaudutaan.
 * Pankkinapit voidaan antaa myös etukäteen.
 */
class Authentication extends Component {
    static propTypes = {
        userMetadata: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            // Latausindikaattori päälle jos pankkinapit on tyhjät.
            isSwitchOn: false,
        };
    }

    onFlipSwitch = (isSwitchOn) => {
        this.setState({
            isSwitchOn,
        });
    };

    render() {
        if (this.isIdentificationOk()) {
            return (
                <Fragment>
                    <div className="u-text-center u-margin">
                        <ThumbUp isUp />
                    </div>
                    <Feedback type="success" title={_trans('authentication.identified.title')}>
                        {_transMd('authentication.identified.message', {
                            termsOfUseUrl: 'https://www.oima.fi/kayttoehdot',
                        })}
                    </Feedback>
                    <div className="u-text-right">
                        <Button
                            iconAfterText
                            href={this.props.userMetadata.isCommune ? Routing.generate('commune_home') : Routing.generate('suoratyo_home')}
                            primary
                            modifierClass="u-margin-top-small"
                            mdIcon="arrow_forward"
                            size="large"
                        >
                            {_trans('authentication.move_to_home')}
                        </Button>
                    </div>
                </Fragment>
            );
        }

        return (
            <Fragment>
                {this.props.userMetadata.isCommune && (
                    <Feedback type="warning" modifierClass="u-margin-bottom-small">
                        Vahva tunnistautuminen tulee pakolliseksi kaikilla henkilökohtaisen avun -palvelun käyttäjille lähitulevaisuudessa.
                        Voit kuitenkin toistaiseksi jatkaa palvelun käyttöä ilman tunnistautumista.
                    </Feedback>
                )}
                {this.renderFeedback()}
                <p>{_trans('authentication.content.justification')}</p>
                <p>{_trans('authentication.content.provider')}
                    <ul>
                        <li>{_trans('form.employee.social_security_number')}</li>
                        <li>{_trans('full_name')}</li>
                    </ul>
                </p>

                <Switch onChange={this.onFlipSwitch} id="mandateSwitch">
                    {_transMd('authentication.accept_terms_of_use_and_mandate', {
                        url: 'https://www.oima.fi/kayttoehdot',
                        url2: Routing.generate('suoratyo_mandate')
                    }, { inline: true, targetBlank: true })}
                </Switch>
                {this.renderVismaConnectNote()}
            </Fragment>
        );
    }

    renderVismaConnectNote() {
        const { isSwitchOn } = this.state;

        if (! isSwitchOn) return null;
        return (
            <div className="u-margin-top">
                {/*Oima käyttää vahvaan tunnistamiseen Visma Connect palvelua.*/}
                <div>
                    <Button
                        href={this.props.userMetadata.isCommune ? Routing.generate('vc_commune_authenticate_redirect') : Routing.generate('vc_authenticate_redirect')}
                        primary
                        modifierClass="u-margin-top-small"
                    >Siirry tunnistautumaan
                    </Button>
                </div>
            </div>
        );
    }

    renderFeedback() {
        if (this.isIdentifierExpired()) {
            return (
                <Feedback modifierClass="u-margin-bottom-small" type="warning" title={_trans('authentication.identifier_expired.title')}>
                    {_trans('authentication.identifier_expired.message')}
                </Feedback>
            );
        }

        return (
            <Feedback type="info" modifierClass="u-margin-bottom-small">
                {_trans('authentication.info')}
            </Feedback>
        );
    }

    isIdentificationOk() {
        return _.get(this.props, 'userMetadata.isIdentified', false) && ! this.isIdentifierExpired();
    }

    isIdentifierExpired() {
        return _.get(this.props, 'userMetadata.userStateOfAffairs.identification.isIdentifierExpired', false);
    }
}

export default hot(Authentication);
