import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { select } from '@rematch/select';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { generateValidationSchema } from 'shared/ReForm/utils/generateValidationSchema';
import { generateInitialValues } from 'shared/ReForm/utils/generateInitialValues';
import { Blocks } from 'shared/ReForm/containers/Blocks';
import { Form, FormField, TextField } from 'shared/components/Formik';
import ActionBar from 'shared/components/ActionBar';
import SubmitButton from 'shared/components/SubmitButton';
import Button from 'shared/components/Button';
import { reportingFormResourceTypes } from 'shared/ReportingForms/constants/reportingFormResourceTypes';
import Page from 'shared/components/Page';
import { componentRootPaths } from 'shared/ReForm/constants/componentRootPaths';
import Badge from 'shared/components/Badge';
import {
    reportingFormStates,
    getReportingFormBadgeTypeByState,
    translateReportingFormState
} from 'shared/ReportingForms/constants/reportingFormStates';

export const ReportingForm = ({ template, relatedResourceId, relatedResourceType, onSubmit, onGoBack, selectedReportingForm, contractType }) => {
    const dispatch = useDispatch();
    const json = template?.data;
    const reportingFormState = selectedReportingForm?.state ?? reportingFormStates.DRAFT;

    const isEmployee = useSelector(select.userMetadata.isEmployee);

    const initialValues = useMemo(() => (
        selectedReportingForm?.data
            ? Object.assign({}, { reportingFormDescription: selectedReportingForm.description, contractType }, selectedReportingForm.data)
            : generateInitialValues(json ?? {} ,{}, { contractType })
    ), [selectedReportingForm, json, contractType]);

    const validationSchema = useMemo(() => json ? generateValidationSchema(json) : {}, [json]);

    return (
        <Page maxWidth="medium">
            <Button flat mdIcon="arrow_back" onClick={onGoBack} modifierClass="u-margin-bottom">
                {_trans('Takaisin', {}, 'common')}
            </Button>
            <h1 className="c-heading-page u-margin-bottom-none">
                {_transObj(template.name ?? {})}
            </h1>
            <div className="u-margin-bottom">
                <Badge
                    type={getReportingFormBadgeTypeByState(reportingFormState)}
                    value={translateReportingFormState(reportingFormState)}
                />
            </div>
            <Formik
                initialValues={initialValues}
                onSubmit={({ reportingFormDescription, ...values }) => {
                    if (selectedReportingForm?.id) {
                        dispatch.reportingForms.putReportingForm({
                            reportingFormId: selectedReportingForm.id,
                            values: {
                                data: values,
                                relatedResourceId,
                                relatedResourceType,
                                templateId: template?.id,
                                reportingFormDescription,
                            }
                        })
                            .then((response) => {
                                onSubmit({ response, values });
                            });
                    } else {
                        dispatch.reportingForms.postReportingForm({
                            data: values,
                            relatedResourceId,
                            relatedResourceType,
                            templateId: template?.id,
                            reportingFormDescription
                        }).then((response) => {
                            onSubmit({ response, values });
                        });
                    }
                }}
                validationSchema={validationSchema}
            >
                {({ values }) => (
                    <Form alignFields="responsive">
                        <FormField name="reportingFormDescription" label={_trans('Kirjauksen selite/nimi', {}, 'reporting_forms')}>
                            <TextField />
                        </FormField>
                        <Blocks
                            json={json}
                            values={values}
                            componentRootPath={`${componentRootPaths.REPORTING_FORM}/Form`}
                            templateSubType={template?.subType}
                        />
                        <ActionBar>
                            <Button flat mdIcon="arrow_back" onClick={onGoBack}>
                                {_trans('Takaisin', {}, 'common')}
                            </Button>
                            <SubmitButton mdIcon="arrow_forward" iconAfterText>
                                {isEmployee
                                    ? _trans('Lähetä', {}, 'common')
                                    : _trans('Tallenna', {}, 'common')}
                            </SubmitButton>
                        </ActionBar>
                    </Form>
                )}
            </Formik>
        </Page>
    );
};

ReportingForm.propTypes = {
    /**
     * Resurssin id johon lomake liittyy. Esim. user-taulun tietty hlö.
     */
    relatedResourceId: PropTypes.number.isRequired,
    /**
     * Resurssin tyyppi. Esim. soppari tai henkilö.
     */
    relatedResourceType: PropTypes.oneOf(Object.values(reportingFormResourceTypes)).isRequired,
    /**
     * Mittööpä sitä tehtäs kun lomake lähetetty onnistuneesti?
     */
    onSubmit: PropTypes.func.isRequired,
    onGoBack: PropTypes.func.isRequired,
    template: PropTypes.object.isRequired,
    selectedReportingForm: PropTypes.object,
    contractType: PropTypes.number,
};

ReportingForm.defaultProps = {
    selectedReportingForm: {},
    contractType: 0,
};

