import React from 'react';
import PropTypes from 'prop-types';
import { CustomComponent } from 'shared/ReForm/containers/CustomBlocks/CustomComponent';
import { inputTypes } from 'shared/ReForm/constants/inputTypes';
import {
    FormField,
    SelectField,
    TextField,
    DateInputField,
    DecimalField,
    IntegerField,
    RadioListField,
    CheckboxListField,
    SwitchField,
} from 'shared/components/Formik';
import FormikDateRangeInput from 'shared/components/Formik/FormikDateRangeInput';
import { parseBlockProperties } from 'shared/ReForm/utils/parseBlockProperties';
import { ChoiceField } from 'shared/components/Formik/ChoiceField';
import { customComponentNames, customComponents } from 'ContractV3/constants/customComponents';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import InputGroup from 'shared/components/InputGroup';
import InputPostfix from 'shared/components/InputPostfix';
import { TextareaField } from 'shared/components/Formik/TextareaField';
import validators, { errorMessages } from 'shared/validators';
import { SocialSecurityNumberField } from 'shared/components/Formik/SocialSecurityNumberField';

export const InputBlock = ({ block, blocks, arrayName, rootName, index, arrayHelpers }) => {
    const blockProperties = parseBlockProperties(block, rootName !== '' ? `${arrayName}.` : '');
    const { name, placeholder, inputLabel, inputPostfix, options, attributes = {}, isValueFixed = false } = blockProperties;
    const fieldProps = getFieldProps(blockProperties);

    // Customkomponenttien on huolehdittava itse kytkeytymisestään Formikkiin.
    // Näin saadaan hieman joustavampi kustomointi per komponentti.
    if (block.type === inputTypes.COMPONENT) {
        const componentName = block.component;
        if (! customComponentNames.includes(componentName)) {
            return (
                <div>Unknown block <b>{componentName}</b></div>
            );
        }

        // Custom-komponenteila saadaan tietyt oletusarvot.
        const customComponent = customComponents[componentName];
        const { hasFormField = false } = customComponent;
        const props = Object.assign({}, blockProperties, { arrayName, index, blocks, rootName, arrayHelpers });

        // Oletuksena kaikki komponentit kääritään FormFieldin sisään
        if (hasFormField) {
            return (
                <CustomComponent componentName={block.fullComponentName} props={props} />
            );
        }

        return (
            <FormField {...fieldProps} isContentFormField={! isValueFixed}>
                <CustomComponent componentName={block.fullComponentName} props={props} />
            </FormField>
        );
    }

    const resolveBlockByType = () => {
        switch (block.type) {
            case inputTypes.TEXT:
            case inputTypes.ENCRYPTED_TEXT: {
                if (inputPostfix) {
                    return (
                        <InputGroup>
                            <TextField name={name} placeholder={placeholder} {...attributes} />
                            <InputPostfix postfix={inputPostfix} />
                        </InputGroup>
                    );
                }
                return <TextField placeholder={placeholder} {...attributes} />;
            }

            case inputTypes.TEXTAREA:
            case inputTypes.ENCRYPTED_TEXTAREA:
                return <TextareaField placeholder={placeholder} {...attributes} className="u-1/1" rows={4} />;

            case inputTypes.ENCRYPTED_SSN:
                return (
                    <SocialSecurityNumberField
                        placeholder={placeholder}
                        size={11}
                        maxLength={11}
                        {...attributes}
                        validate={(value) => {
                            if (fieldProps.isRequired && value && value.trim() !== '' && ! validators.isSocialSecurityNumber(value)) {
                                return errorMessages.isSocialSecurityNumber;
                            }
                        }}
                    />
                );

            case inputTypes.DECIMAL: {
                if (inputPostfix) {
                    return (
                        <InputGroup>
                            <DecimalField placeholder={placeholder} name={name} {...attributes} />
                            <InputPostfix postfix={inputPostfix} />
                        </InputGroup>
                    );
                }
                return <DecimalField placeholder={placeholder} {...attributes} />;
            }

            case inputTypes.CURRENCY: {
                return (
                    <InputGroup>
                        <DecimalField placeholder={placeholder} name={name} {...attributes} />
                        <InputPostfix postfix={`${window._currencySymbol} ${inputPostfix ?? ''}`} />
                    </InputGroup>
                );
            }

            case inputTypes.INTEGER:
                if (inputPostfix) {
                    return (
                        <InputGroup>
                            <IntegerField placeholder={placeholder} name={name} {...attributes} />
                            <InputPostfix postfix={inputPostfix} />
                        </InputGroup>
                    );
                }
                return <IntegerField placeholder={placeholder} {...attributes} />;

            case inputTypes.RADIO:
                return <RadioListField name={name} options={options} />;

            case inputTypes.CHECKBOX:
                return <CheckboxListField name={name} options={options} />;

            case inputTypes.SWITCH:
                return (
                    <SwitchField name={name}>
                        {inputLabel}
                    </SwitchField>
                );

            case inputTypes.CHOICE:
                return (
                    <ChoiceField name={name} options={options} alignment={attributes?.alignment || 'vertical'} />
                );

            case inputTypes.DROPDOWN:
                return <SelectField options={options} placeholder={placeholder} />;

            case inputTypes.DATE:
                return <DateInputField placeholder={placeholder ?? ''} />;

            case inputTypes.DATE_RANGE:
                return <FormikDateRangeInput name={name} />;
        }
    };

    if (! fieldProps?.label && block.type === inputTypes.TEXTAREA) {
        return (
            <FormField {...fieldProps} isContentFormField={! isValueFixed} modifierClass="o-form__field--vertical">
                {resolveBlockByType()}
            </FormField>
        );
    }

    return (
        <FormField {...fieldProps} isContentFormField={! isValueFixed}>
            {resolveBlockByType()}
        </FormField>
    );
};

InputBlock.propTypes = {
    block: PropTypes.object.isRequired,
    // Mahdolliset monistettavat palikat (FieldArrayn sisällä)
    blocks: PropTypes.array,
    // FieldArrayn kanssa käytettävä päänimen ja indeksin yhdistelmä (esim. salaries.0)
    arrayName: PropTypes.string.isRequired,
    // FieldArrayn kanssa käytettävä päänimi
    rootName: PropTypes.string,
    // FieldArrayn antama indeksi.
    index: PropTypes.number,
    // FieldArray helpperit.
    arrayHelpers: PropTypes.object,
};

InputBlock.defaultProps = {
    blocks: [],
    rootName: '',
    index: 0,
    arrayHelpers: {},
};
